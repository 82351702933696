import { BaseElement, html, css } from 'Components';
import { Fetcher } from 'Utils';

class Popup extends BaseElement {
  static get styles() {
    return [
      css`
        .buttons {
          display:flex;
          justify-content:space-between;
        }  

        #name {
          font-weight:bold;
        }
      `
    ];
  }

  static get properties() {
    return {
      apipath: { type: String },
      label: { type: String },
    }
  }

  constructor() {
    super();
    this.apipath = '';
    this.label = 'Supprimer';
  }

  firstUpdated() {
    this.modal = this.shadowRoot.querySelector('modal-dialog');
    this.nameEl = this.shadowRoot.querySelector('#name');
    this.btSave = this.shadowRoot.querySelector('#bt_save');
  }

  show(item, tabNumber) {
    this.item = item;
    this.tabNumber = tabNumber;
    this.nameEl.innerHTML = this.item.tabs[tabNumber].title;
    this.modal.show();
  }

  async handleSubmit() {
    this.modal.loadingButtons();
    const itemId = this.item.id || this.item._id;
    this.item.tabs.splice(this.tabNumber, 1);
    const response = await Fetcher.post(`${this.apipath}/${itemId}/update`, this.item);
    this.dispatchEvent(new CustomEvent('deleted', { detail: response.data }));
    this.modal.hide();
  }

  render() {
    return html`
      <modal-dialog label="${this.label}">
        <br/>
        <p>Etes vous sûr(e) de vouloir supprimer l'onglet &laquo;&nbsp;<span id="name"></span>&nbsp;&raquo; ?</p>
        <br/>
        <sl-button slot="bt1" close="true" variant="text">Annuler</sl-button>
        <sl-button slot="bt2" variant="danger" @click="${this.handleSubmit}">Supprimer</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('entity-tab-del', Popup);