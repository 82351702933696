import { html, css } from 'Components';
import TableBasic from 'Components/abstract/TableBasic.js'
import { Router } from 'Router';

import './edit.js';
import './live.js';

class ConferencePage extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`
        table tr {
          padding:0;
        }

        table td {
          padding:0px;
          padding-left: 7px;
          padding-bottom: 0px;
        }

        .center {
          padding:0px;
        }

        m-icon[name="check"] {
          color:var(--sl-color-success-700);
          font-size:1.6em;
          margin-top:2px;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            add:'Create a conference',
            empty:'No conference available',
            title:'Conferences',
            conference:'conference',
            join:'Join',
          }
        },
        french:{
          translation: {
            add:'Créer une conférence',
            empty:'Aucune conférence disponible',
            title:'Conférences',
            conference:'conférence',
            join:'Rejoindre',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/user/conferences';
    this.urlVar = 'idc';
    this.itemTitleField = 'title';
    this.eventUpdated = 'conference-updated';
  }

  _getTableHeader() {
    if (!this.items) return;
    const totalCount = this.items.length || 0;
    let totalText = totalCount >= 1 ? `${this._tl('conference')}s` : this._tl('conference');
    totalText = totalText.toLowerCase();
    return `${totalCount} ${totalText}`;
  }

  joinConference(ev, item) {
    ev.stopPropagation();
    Router.go(`/private/dev/conference/${item._id}`);
  }

  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <thead>
          <tr>
            <th>${this._tl('title')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>      
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${this._getItemTitle(item)}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}">
                    <m-icon slot="prefix" name="edit"></m-icon>
                    ${this._tl('modify')}
                  </sl-menu-item>
                  <sl-menu-item @click="${(ev) => this.joinConference(ev, item)}">
                    <m-icon slot="prefix" name="login"></m-icon>
                    ${this._tl('join')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._tl('remove')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderEditComponent() {
    return html`<conference-edit id="modal-edit"></conference-edit>`;
  }

  render() {    
    return html`
      <section-header backroute="../" micon="person_play">${this._tl('title')}</section-header>
      <br/>
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderEditComponent()}
      ${this._renderDeleteModal()}
      <br/><br/><br/>
    `;
  }
}

customElements.define('page-conference', ConferencePage);