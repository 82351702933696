import { html, css } from 'Components';
import DrawerForm from 'Components/abstract/DrawerForm.js';

class ConferenceEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        box-styled {
          font-size:0.9em;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            add:'Create a conference',
            title:'Conference',
          }
        },
        french:{
          translation: {
            add:'Créer une conférence',
            title:'Conférence',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/user/conferences';
    this.urlVar = 'idc';
    this.itemTitleField = 'title';
    this.eventUpdated = 'conference-updated';
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
    this._logEnable = false;
  }

  getTranslatePayload() {
    const payload = {
      title: this._lookupField('title').value
    }
    return payload;
  }

  onTranslated(ev) {
    this._lookupField('title', 'en').value = ev.detail.title;
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();
  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer>
        <form>
          <br/>
          <tab-group level="c" class="main" .item=${this.item}>
            <sl-tab slot="nav" panel="general">${this._tl('title')}</sl-tab>

            <sl-tab-panel name="general">
              <tab-group-lg level="f" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
                <br/>
                <sl-input size="small" name="title" class="labol" label="Titre"></sl-input><br/>
              </tab-group-lg>
            </sl-tab-panel>


          </tab-group>
        </form>

        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
        
      </modal-drawer>
    `;
  }

}

customElements.define('conference-edit', ConferenceEdit);