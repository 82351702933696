import { BaseElement, html, css, getFormControls } from 'Components';
import { Fetcher, UrlParams } from 'Utils';
import ATTRIBUTES from './attributes.js';
import { v4 as uuidv4 } from 'uuid';

class Popup extends BaseElement {
  static get styles() {
    return [
      css`
      form {
        width:600px;
        max-width:90vw;
        display:flex;
        flex-direction:column;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
      }

      .line {
        display:flex;
        flex-direction:row;
        align-items: center;
        gap: 10px;
        width:100%;
        justify-content: flex-start
      }

      sl-input {
        width:100%;
      }

      sl-input::part(input) {
        font-size:13px;
      }

      label {
        min-width:150px;
      }

      label.disabled {
        color: var(--sl-color-gray-400);
      }

      .buttons {
        display:flex;
        justify-content:space-between;
        margin-top:40px;
      }

      sl-select {
        width:100%;
      }

      sl-select::part(display-input) {
        font-size:13px;
      }

      sl-option::part(base) {
        font-family: Calibri;
        font-size:14px;
      }
      `
    ];
  }

  static get properties() {
    return {
      name: { type: String }, // used chrome autocomplete memory
      apipath: { type: String },
      minlength: { type: Number },
      maxlength: { type: Number },
      item: { type: Object },
      parent: { type: Object }
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            cancel:'Cancel',
            save:'Save'
          },
        },
        french:{
          translation: {
            cancel:'Annuler',
            save:'Enregistrer'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.name = '';
    this.item = null;
    this.parent = null;
    this.apipath = '';
    this.minlength = 0;
    this.maxlength = 255;
  }

  firstUpdated() {
    this.modal = this.shadowRoot.querySelector('modal-dialog');
    this.input = this.shadowRoot.querySelector('sl-input[id="name"]');
    this.inputUuid = this.shadowRoot.querySelector('sl-input[name="uuid"]');
    this.selectTab = this.shadowRoot.querySelector('sl-select[name="tab"]');
    this.selectTabContainer = this.shadowRoot.querySelector('div[name="select_tag_container"]');
    this.selectType = this.shadowRoot.querySelector('sl-select[name="type"]');
    this.form = this.shadowRoot.querySelector('form');
    this.btSave = this.shadowRoot.querySelector('sl-button#btsave');
  }

  show(parent, attributeNumber) {
    this.formReset();
    this.parent = parent;
    this.attributeNumber = attributeNumber;
    if (!attributeNumber) {
      this.modal.label = 'Ajouter un attribut';
      this.inputUuid.value = uuidv4();
      this.selectType.value = Object.keys(ATTRIBUTES)[0];
      this.selectTab.value = UrlParams.get('tab') || (this.parent.tabs ? this.parent.tabs[0].id : '');
    } else {
      this.modal.label = 'Modifier un attribut';
      this.attribute = parent.attributes[attributeNumber];
      this.input.value = this.attribute.name;
      this.inputUuid.value = this.attribute.uuid || uuidv4();
      this.selectType.value = this.attribute.type;
      this.selectTab.value = this.attribute.tab;
      this.btSave.disabled = false;
    }
    this.modal.show();
    setTimeout(() => { this.focusField() }, 200);
  }

  focusField() {
    this.input.focus();
    // go to the end of the input
    this.input.setSelectionRange(this.input.value.length, this.input.value.length);
  }

  hide() {
    this.modal.hide();
  }

  onInput(e) {
    e.preventDefault();
    e.stopPropagation();   

    if (this.minlength && this.input.value.length < this.minlength) {
      this.btSave.disabled = true;
      return;
    }

    if (this.maxlength && this.input.value.length > this.maxlength) {
      this.btSave.disabled = true;
      return;
    }

    this.btSave.disabled = false;
  }
  
  formReset() {
    const elements = getFormControls(this.form);
    elements.forEach(el => {
      el.value = '';
      el.setCustomValidity('');
      el.reportValidity();
    });
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    this.btSave.loading = true;
    this.btSave.disabled = true;

    const url = `${this.apipath}/${this.parent._id}/update`;

    this.parent.attributes = this.parent.attributes || [];

    const item = {
      name: this.input.value,
      type: this.selectType.value,
      uuid: this.inputUuid.value
    }

    if (this.selectTab) {
      item.tab = this.selectTab.value;
    }

    if (this.attributeNumber) {
      this.parent.attributes[this.attributeNumber] = item;
    } else {
      this.parent.attributes.push(item);
    }

    const response = await Fetcher.post(url, this.parent);
    this.btSave.loading = false;
    this.btSave.disabled = false;

    if (response.ok) {
      this.dispatchEvent(new CustomEvent('updated', { detail: { parent:this.parent } }));
      this.form.reset();
      this.modal.hide();
    } else {
      if (response.error === 'EVALIDATION_FAILED') {
        this.input.setCustomValidity('Valeur obligatoire et longueur minimum de 3 caractères');
        this.input.reportValidity();
      }
    }
  }

  onSelectChange(ev) {
    if (!this.input.value && ev.target.value.startsWith('x-')) {
      this.input.value = ATTRIBUTES[ev.target.value];
      this.btSave.disabled = false;
    }
  }
  
  render() {
    // @TODO refactor with drawer ?
    
    return html`
      <modal-dialog>
        <style type="text/css">${this.constructor.styles}</style>
        <form @submit="${this.handleSubmit}">
          <div class="line" name="select_tag_container">
            <label>Onglet</label>
            <sl-select size="small" name="tab">
              ${this.parent?.tabs?.map(tab => html`<sl-option value="${tab.id}">${tab.title}</sl-option>`)}
            </sl-select>
          </div>
          <div class="line">
            <label>Type</label>
            <sl-select size="small" name="type" @sl-change="${this.onSelectChange}">
              ${Object.keys(ATTRIBUTES).map(key => {
                if (key.length) {
                  return html`<sl-option value="${key}">${ATTRIBUTES[key]}</sl-option>`
                } else {
                  return html`<sl-divider></sl-divider>`
                }
              })}
            </sl-select>
          </div>
          <div class="line">
            <label>Intitulé</label>
            <sl-input 
              size="small" 
              type="text" 
              no-spin-buttons 
              required 
              minlength="${this.minlength}" 
              maxlength="${this.maxlength}"
              id="name"
              name="${this.name}" 
              value="${this.item ? this.item.name : ''}"
              @sl-input="${this.onInput}">
              ${this.item ? this.item.name : ''}
            </sl-input>
          </div>
          <div class="line">
            <label class="disabled">UUID</label>
            <sl-input size="small" type="text" no-spin-buttons name="uuid" readonly disabled></sl-input>
          </div>
        </form>
        <sl-button slot="bt1" close="true" variant="text">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" @click="${this.handleSubmit}" id="btsave" variant="primary" disabled>${this._tl('save')}</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('entity-attribute-edit', Popup);