import { PageElement, html, css, formCss } from 'Components';
import { Fetcher, Lang } from 'Utils';


class ConferenceLivePage extends PageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        sl-spinner {
          margin-top:100px;
          display: block;
          margin-left:auto;
          margin-right:auto;
          font-size:100px;
        }

        .welcome {
          display:flex;
          gap:10px;
          margin-left:auto;
          margin-right:auto;
        }

        .welcome .setup_video {
          display:flex; 
          flex-direction:column;
          gap:1px;
        }

        .welcome .video_stream {
          width:510px;
          height: calc(510px / 1.74); /* Calculer la hauteur dynamiquement */
          border:1px solid var(--sl-color-neutral-300);
          display:flex;
          align-items:center;
          justify-content:center;
          overflow:hidden;
          position:relative;
        }

        .welcome .video_stream video {
          width: 100%; /* Ajuster selon vos besoins */
          height: auto;
          transform: scaleX(-1); /* Inverser horizontalement la vidéo */
          display:none;
        }

        .welcome .video_stream canvas {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          transform: scaleX(-1); /* Inverser horizontalement la vidéo */
        }

        .welcome .video_settings {
          padding:5px;
          border:1px solid var(--sl-color-neutral-300);
          display:flex;
          gap:15px;
          padding-left:10px;
        }

        .welcome .video_settings m-icon {
          font-size: 1.4em;
        }

        .welcome .setup_sound {
          width:440px;
          border:1px solid var(--sl-color-neutral-300);
        }

        .welcome .setup_sound m-icon[name="radio_button_unchecked"] {
          color:var(--sl-color-neutral-300);
        }

        .welcome .setup_sound m-icon.blue {
          color:var(--sl-color-primary-600);
        }

        .welcome .audio_settings m-icon {
          font-size: 1.4em;
        }

        .welcome .audio_settings .top,
        .welcome .audio_settings .bottom {
          display:flex;
          align-items:center;
          gap:15px;
          padding-left:10px;
        }

        .welcome .audio_settings .top {
          justify-content:space-between;
        }

        sl-details::part(header) {
          background-color: var(--sl-color-neutral-100);
          font-weight: bold;
        }

        sl-details::part(content) {
          font-size:0.9em;
        }

        .no_cam {
          text-align:center;
        }
        
        .no_cam m-icon[name="videocam_off"] {
          font-size: 50px;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            add:'Create a conference',
            empty:'No conference available',
            title:'Conferences',
            conference:'conference',
            join:'Join',
            camera_off:'Your is camera turned off',
            computer_audio:'Computer audio',
            custom_setup:'Custom setup',
            no_audio_usage:'Don\'t use audio',
          }
        },
        french:{
          translation: {
            add:'Créer une conférence',
            empty:'Aucune conférence disponible',
            title:'Conférences',
            conference:'conférence',
            join:'Rejoindre',
            camera_off:'Votre caméra est éteinte',
            computer_audio:'Audio de l\'ordinateur',
            custom_setup:'Configuration personnalisée',
            no_audio_usage:'Ne pas utiliser l\'audio',
          }
        }
      }
    ]
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      conference: { type: Object },
      cameraOn: { type: Boolean },
      audioOn: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/user/conferences';
    this.loading = true;
    this.conference = null;
    this.cameraOn = false;
    this.audioOn = false;
    this.videoElement = null;
    this.debug = false;
    /*
    this.segmentPersonOptions = {
      internalResolution: 'medium',
      segmentationThreshold: 0.2
    };
    */

    this.segmentPersonOptions = {
      flipHorizontal: false, // Whether to flip the input video horizontally
      internalResolution: 'medium', // The resolution for internal processing (options: 'low', 'medium', 'high')
      segmentationThreshold: 0.7, // Segmentation confidence threshold (0.0 - 1.0)
      maxDetections: 10, // Maximum number of detections to return
      scoreThreshold: 0.2, // Confidence score threshold for detections (0.0 - 1.0)
      nmsRadius: 20, // Non-Maximum Suppression (NMS) radius for de-duplication
      minKeypointScore: 0.3, // Minimum keypoint detection score (0.0 - 1.0)
      refineSteps: 10, // Number of refinement steps for segmentation
    }
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.loadRequiredScripts();
    
    //const optsResNet = { architecture: 'ResNet50', outputStride: 32, quantBytes: 2 };
    const optsMobileNet = { architecture: 'MobileNetV1', outputStride: 16, quantBytes: 2, multiplier: 0.75 };
    const opts = optsMobileNet;

    this.net = await bodyPix.load(opts);
    this.loading = false;
  }

  loadScriptIfNotPresent(src) {
    return new Promise((resolve, reject) => {
      // Vérifier si le script est déjà présent dans le document
      if (document.querySelector(`script[src="${src}"]`)) {
        resolve();  // Si le script est déjà présent, ne rien faire
        return;
      }

      // Créer une nouvelle balise <script>
      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      // Définir les événements de chargement et d'erreur
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${src}`));

      // Insérer la balise <script> dans le DOM (fin de body)
      document.body.appendChild(script);
    });
  }

  // Charger les scripts TensorFlow.js et BodyPix si nécessaire
  async loadRequiredScripts() {
    try {
      await this.loadScriptIfNotPresent('https://cdn.jsdelivr.net/npm/@tensorflow/tfjs');
      await this.loadScriptIfNotPresent('https://cdn.jsdelivr.net/npm/@tensorflow-models/body-pix');
      console.log('Scripts TensorFlow.js et BodyPix chargés.');
    } catch (error) {
      console.error(error.message);
    }
  }

  async firstUpdated() {
    super.firstUpdated();

    // fetch last part of current url, which is the uid of the conference
    const urlParts = window.location.pathname.split('/');
    const conferenceId = urlParts[urlParts.length - 1];
    const response = await Fetcher.get(`${this.apiEndpoint}/${conferenceId}`);
    if (response && response.data) {
      this.conference = response.data;
    }

    setTimeout(() => {
      const container = this.qs('.setup_sound');
      container.addEventListener('sl-show', event => {
        if (event.target.localName === 'sl-details') {
          [...container.querySelectorAll('sl-details')].map(details => (details.open = event.target === details));
        }
      });
    }, 100);
  }


  async toggleCamera() {
    this._log.debug('toggleCamera');
    this.cameraOn = !this.cameraOn;
    await this.updateComplete;
    if (this.cameraOn) {
      await this.startCamera();
    } else {
      this.stopCamera();
    }
  }

  async startCamera() {
    this._log.debug('startCamera');
    try {

      this.videoElement = this.shadowRoot.querySelector('video');
      if (!this.videoElement) {
        throw new Error('Video element not found');
      }
      // set width / height of video element depending of the aspect ratio container
      this.videoElement.width = this.videoElement.parentNode.parentNode.clientWidth;
      this.videoElement.height = this.videoElement.width * 0.74;
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      if (this.videoElement.srcObject) this.stopCamera();
      this.videoElement.srcObject = stream;

      // Attendre que la vidéo soit prête
      this.videoElement.onloadedmetadata = () => {
        this.videoElement.play();
        this.loadAndPredict(this.videoElement);
        this._log.debug('startCamera: success');
      };

    } catch (error) {
      console.error('Error accessing camera:', error);
      this.cameraOn = false;
    }
  }

  async blurMe(videoElement) {
    const predict = async () => {
      const segmentation = await this.net.segmentPerson(videoElement, this.segmentPersonOptions);
      const backgroundBlurAmount = 12;
      const edgeBlurAmount = 2;
      const flipHorizontal = false;
      bodyPix.drawBokehEffect(
        this.canvas,
        videoElement,
        segmentation,
        backgroundBlurAmount,
        edgeBlurAmount,
        flipHorizontal
      );
      this.animationFrameId = requestAnimationFrame(predict);
    };

    predict();

  }

  async backgroundMe(videoElement) {

    const backgroundImage = new Image();
    backgroundImage.src = '/assets/img/visuals/bg1.webp';
    backgroundImage.width = this.canvas.width;
    backgroundImage.height = this.canvas.height;

    const predict = async () => {
      const segmentation = await this.net.segmentPerson(videoElement, this.segmentPersonOptions);
      const ctx = this.canvas.getContext('2d');

      // Dessiner l'image de fond
      ctx.drawImage(backgroundImage, 0, 0, this.canvas.width, this.canvas.height);

      const background = { r: 0, g: 0, b: 0, a: 0 };
      const mask = bodyPix.toMask(
        segmentation,
        background,
        { r: 0, g: 0, b: 0, a: 155 }
      );

      if (mask) {
          ctx.putImageData(mask, 0, 0);
          ctx.globalCompositeOperation = 'source-in';

          // 3. Drawing the Background
          if (backgroundImage.complete) {
              ctx.drawImage(backgroundImage, 0, 0, this.canvas.width, this.canvas.height);
          } else {
              // If the image is not loaded yet, wait for it to load and then draw
              backgroundImage.onload = () => {
                  ctx.drawImage(backgroundImage, 0, 0, this.canvas.width, this.canvas.height);
              };
          }

          // Draw the mask (segmentation)
          ctx.globalCompositeOperation = 'destination-over';
          ctx.drawImage(videoElement, 0, 0, this.canvas.width, this.canvas.height);
          ctx.globalCompositeOperation = 'source-over';

          // Add a delay to control the frame rate (adjust as needed) less CPU intensive
          // await new Promise((resolve) => setTimeout(resolve, 100));

          // Continue updating the canvas
          requestAnimationFrame(predict);
      }
    };

    backgroundImage.onload = () => {
      predict();
    };
  }

  async loadAndPredict(videoElement) {
    this.canvas = this.canvas || document.createElement('canvas');
    this.canvas.width = videoElement.videoWidth;
    this.canvas.height = videoElement.videoHeight;
    this.qs('.video_preview').appendChild(this.canvas);

    this.blurMe(videoElement);
    //this.backgroundMe(videoElement);
    
  }

  stopCamera() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    if (this.videoElement && this.videoElement.srcObject) {
      const stream = this.videoElement.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => { track.stop(); });
      this.videoElement.srcObject = null;
    }

    this.cameraOn = false;
  }

  customSetupShow() {
    this.qs('#modal-custom-setup').show();
  }

  render() {
    
    if (this.loading) {
      return html`<sl-spinner></sl-spinner>`;
    }

    return html`
      <section-header micon="person_play">${Lang.lookup(this.conference, 'title')}</section-header>
      <br/>
      <div class="welcome">
        <div class="setup_video">
          <div class="video_stream">
            ${this.cameraOn 
              ? html`<div class="video_preview"><video autoplay playsinline="true" disablepictureinpicture></video></div>` 
              : html`<div class="no_cam"><m-icon name="videocam_off"></m-icon><br/>${this._tl('camera_off')}</div>`
            }
          </div>
          <div class="video_settings">
            <m-icon name="${this.cameraOn ? 'videocam': 'videocam_off'}"></m-icon>
            <sl-switch @sl-change=${this.toggleCamera} ?checked=${this.cameraOn}></sl-switch>
          </div>
        </div>
        <div class="setup_sound">
          <sl-details summary="${this._tl('computer_audio')}" open>
            <m-icon name="radio_button_unchecked" slot="expand-icon"></m-icon>
            <m-icon name="radio_button_checked" class="blue" slot="collapse-icon"></m-icon>
            <div class="audio_settings">
              <div class="top">
                <div>${this._tl('custom_setup')}</div>
                <m-icon name="tune" clickable @click=${this.customSetupShow}></m-icon>
              </div>
              <sl-divider></sl-divider>
              <div class="bottom">
                <m-icon name="${this.audioOn ? 'volume_up': 'volume_off'}"></m-icon>
                <sl-switch @sl-change=${() => { this.audioOn = !this.audioOn; }} ?checked=${this.audioOn}></sl-switch>
              </div>
            </div>
          </sl-details>
          <sl-details summary="${this._tl('no_audio_usage')}">
            <m-icon name="radio_button_unchecked" slot="expand-icon"></m-icon>
            <m-icon name="radio_button_checked" class="blue" slot="collapse-icon"></m-icon>
            Join muted to avoid causing audio disruptions.
          </sl-details>
        </div>
      </div>

      <modal-drawer id="modal-custom-setup" label="${this._tl('custom_setup')}">
        <tab-group>
          <sl-tab slot="nav" panel="audio">Audio</sl-tab>
          <sl-tab slot="nav" panel="video">Video</sl-tab>

          <sl-tab-panel name="audio">
            <section-header size="small">Speaker</section-header>
            <sl-select size="small" value="default">
              <sl-option value="default">Par défault</sl-option>
            </sl-select>
            <br/><br/>
            <section-header size="small">Microphone</section-header>
            <sl-select size="small" value="default">
              <sl-option value="default">Par défault</sl-option>
            </sl-select>
            <br/><br/>
            <sl-switch class="labol">Noise suppression</sl-switch>
          </sl-tab-panel>

        </tab-group>
      </modal-drawer>
    `;
  }
}

customElements.define('page-conference-live', ConferenceLivePage);
