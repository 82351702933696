import { BaseElement, html, css } from 'Components';
import { Fetcher } from 'Utils';

class Popup extends BaseElement {
  static get styles() {
    return [
      css`
        .buttons {
          display:flex;
          justify-content:space-between;
        }  

        #name {
          font-weight:bold;
        }
      `
    ];
  }

  static get properties() {
    return {
      apipath: { type: String },
      label: { type: String },
    }
  }

  constructor() {
    super();
    this.apipath = '';
    this.label = 'Supprimer';
  }

  firstUpdated() {
    this.modal = this.shadowRoot.querySelector('#modal-entity-attribute-del');
    this.nameEl = this.shadowRoot.querySelector('#name');
  }

  show(item, attributeNumber) {
    this.item = item;
    this.attributeNumber = attributeNumber;
    this.nameEl.innerHTML = this.item.attributes[attributeNumber].name;
    this.modal.show();
  }

  async handleSubmit() {
    const buttons = this.modal.loadingButtons();
    const itemId = this.item.id || this.item._id;
    this.item.attributes.splice(this.attributeNumber, 1);
    const response = await Fetcher.post(`${this.apipath}/${itemId}/update`, this.item);
    this.dispatchEvent(new CustomEvent('deleted', { detail: response.data }));
    this.modal.hide();
  }
  
  render() {
    return html`
      <modal-dialog label="${this.label}" id="modal-entity-attribute-del">
        <br/>
        <p>Etes vous sûr(e) de vouloir supprimer l'attribut &laquo;&nbsp;<span id="name"></span>&nbsp;&raquo; ?</p>
        <br/>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="danger" @click="${this.handleSubmit}">Supprimerx</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('entity-attribute-del', Popup);